import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Img from 'gatsby-image'
import '../styles/main.scss'
import AnimateComponent from '../components/animate-component'
import SpecializationCarousel from '../components/specialization-carousel'

class Kancelaria extends React.Component {

    componentDidMount() {
        this.initCarousels();
    }

    componentWillUnmount() {
        this.unmountCarousels();
    }

    initCarousels = () => {
        if (this.props.location.pathname.includes('/kancelaria/')) {
            const carousel = require('../js/carousel');
            this.specializationCarousel = new carousel('cancellaryDescription');
            this.specializationCarousel.run();
        }
    }

    unmountCarousels = () => {
        this.specializationCarousel.stop();
        this.specializationCarousel = null;
    }

    render() {
        return (
          <Layout>
                <SEO title="O Kancelarii | Świecki Kancelaria"/>
                <AnimateComponent styleName="cancellary-description" id="cancellaryDescription" tag='section' >
                    <div
                        className='cancellary-description__row wow fadeInUp'
                        >
                        <div className="cancellary-description__container">
                            <div
                                className="cancellary-description__text-block cancellary-description__text-block--top">
                                <div className="cancellary-description__decoration" aria-hidden="true">&#167;1
                                </div>
                                <h1>Kancelaria</h1>
                                <h2>Naszym priorytetem jest osiąganie sukcesów w zleconych sprawach.</h2>
                                <p >Klienci doceniają naszą umiejętność pracy zespołowej, która zapewnia
                                    wielopłaszczyznowe i kompleksowe rozwiązanie zleconych spraw.</p>
                                <p>W gronie naszych prawników są specjaliści z zakresu poszczególnych dziedzin
                                    prawa. Kancelaria współpracuje z doradcami podatkowymi, audytorami, ekonomistami
                                    oraz innymi ekspertami, którzy w razie potrzeby stanowią wsparcie w wykonywaniu
                                    zadań powierzonych przez Klienta.</p>
                            </div>
                            <div className="cancellary-description__photo-container">
                                <Img
                                    fluid={this.props.data.imageKancelaria1.childImageSharp.fluid}
                                    className="cancellary-description__photo cancellary-description__photo--top"
                                    alt="grupa prawników siedzących przy stole z laptopami i dokumentami. U szczytu stołu siedzi Pan Adam Świecki, mówiąc coś i gestykulując."/>
                            </div>
                        </div>
                    </div>
                    <div
                        className='cancellary-description__row wow fadeInUp'>
                        <div
                            className="cancellary-description__container cancellary-description__container--middle">
                            <div
                                className="cancellary-description__photo-container cancellary-description__photo-container--middle">
                                <Img
                                    fluid={this.props.data.imageKancelaria2.childImageSharp.fluid}
                                    className="cancellary-description__photo cancellary-description__photo--middle"
                                    alt="Adam Adam Swiecki siedzący na skórzanej kanapie. Na przeciwko, z dokumentami w ręku siedzi prawniczka."/>
                            </div>
                            <div
                                className="cancellary-description__text-block cancellary-description__text-block--middle">
                                <div
                                    className="cancellary-description__decoration cancellary-description__decoration--middle"
                                    aria-hidden="true">&#167;2
                                </div>
                                <h3>Kancelaria</h3>
                                <h4>Wiedza,
                                    <br/>nowoczesność<br/>
                                    i kreatywność.</h4>
                                <p>Atutem kancelarii jest połączenie dogłębnej wiedzy z nowoczesnym sposobem
                                    działania i kreatywnością w doradztwie prawnym, które umożliwiają rozwiązywanie
                                    najtrudniejszych problemów prawnych pojawiających się przy prowadzeniu
                                    działalności gospodarczej.</p>
                            </div>
                        </div>
                    </div>
                    <div
                        className='cancellary-description__row wow fadeInUp'
                        >
                        <div
                            className="cancellary-description__container cancellary-description__container--bottom">
                            <div
                                className="cancellary-description__text-block cancellary-description__text-block--bottom">
                                <div
                                    className="cancellary-description__decoration cancellary-description__decoration--bottom"
                                    aria-hidden="true">&#167;3
                                </div>
                                <h4>Zakres każdej pracy, którą wykonujemy dla Klientów,
                                    <br/>
                                    jest starannie zaplanowany i uzgodniony.
                                </h4>
                                <p>Dokładamy wszelkich starań, aby jak najlepiej zrozumieć kluczowe dla Klienta
                                    zagadnienia, a następnie ustalić sposób postępowania. Stała komunikacja i
                                    kontrola zapewniają realizację projektu zgodnie z oczekiwaniami Klienta.
                                    Sprawdzone metody planowania pracy i weryfikacji jej realizacji oraz regularna
                                    komunikacja z Klientem zapewniają przewidywalność efektów i minimalizują
                                    występowanie niepożądanych zdarzeń.</p>
                            </div>
                            <div
                                className="cancellary-description__photo-container cancellary-description__photo-container--bottom">
                                <Img
                                    fluid={this.props.data.imageKancelaria3.childImageSharp.fluid}
                                    className="cancellary-description__photo cancellary-description__photo--bottom"
                                    alt="Dwie prawczki siedzace na skórzanych fotelach"/>
                                <Img
                                    fluid={this.props.data.imageKancelaria4.childImageSharp.fluid}
                                    className="cancellary-description__photo cancellary-description__photo--bottom"
                                    alt="Kobieta i mężczyzna schodzący po schodach"/>
                            </div>
                        </div>
                    </div>
                    <SpecializationCarousel title='Mocną stroną kancelarii są jej specjalizacje'/>
                </AnimateComponent>
            </Layout>

        )
    }
}

export default Kancelaria

export const pageQuery = graphql `
  query {
    imageKancelaria1: file(relativePath: {eq: "images/swiecki-kancelaria-1.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageKancelaria2: file(relativePath: {eq: "images/swiecki-kancelaria-2.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageKancelaria3: file(relativePath: {eq: "images/swiecki-kancelaria-new1.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageKancelaria4: file(relativePath: {eq: "images/swiecki-kancelaria-new2.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }


  }
`