import React from 'react'
import {Link} from 'gatsby'
import {StaticQuery, graphql} from 'gatsby'
import utils from '../js/utils'
import Image from './image'

class SpecializationCarousel extends React.Component {

    render() {
        const items = [];
        const specs = this.props.data.allWordpressWpSpecjalizacje.edges;
        specs.forEach(item => items.push(item));
        while (items.length % 5 !== 0) {
            let randomIndex = Math.floor(Math.random() * (Math.floor(items.length) - 0)) + 0;
            // check if item is in array, if not - random once again 
            if(items.slice(items.length -5).includes(items[randomIndex])) { 
              continue;   
            } else {
            items.push(items[randomIndex])    
            }
        }
        const carouselItems = utils.chunkArray(items, 5);
        return (
            <div className="specialization-carousel">
                <div className='carousel'>
                    <Image
                        src='arrowLeftGreen'
                        type='icon'
                        class='carousel__arrow carousel__arrow--left carousel-left'
                        alt='left'/>
                    <Image
                        src='arrowRightGreen'
                        type='icon'
                        class='carousel__arrow carousel__arrow--right carousel-right'
                        alt='right'/>
                    <h3 class="carousel__title">{this.props.title}</h3>
                    <div className='carousel__container'>

                        {carouselItems.map((item, i) => (
                            <div key={i} className="carousel__item">
                                {item.map((box, i) => (
                                    <Link to={`/specjalizacje/${box.node.slug}`} key={box.node.id + i}>
                                        <div className="carousel__box">
                                            <img
                                                src={box.node.featured_media.localFile.publicURL}
                                                alt=""
                                                className="carousel__icon"/>
                                            <p className="carousel__text">{(box.node.title.includes('RODO')) ? box.node.title.split(' ')[0] : (box.node.title.includes('Spory')) ? `${box.node.title.split(' ')[0]} ${box.node.title.split(' ')[1]}` : box.node.title}</p>
                                        </div>
                                    </Link>
                                ))}
                            </div>
                        ))}
                    </div>
                    <div class="carousel__navigation navigation">
                        <div class="navigation__controls">
                            {carouselItems.map((item, i) => (
                                <React.Fragment key={i}>
                                <p role="button" tabIndex="0" className={`navigation__control ${i===0 ? 'active': ''}`}>{`0${i+1}`}</p>
                                <div class={`navigation__progress-bar animate ${i !== 0 ? 'none' : ''}`}></div>
                                </React.Fragment>
                            ))}
                          
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default props => (
    <StaticQuery
    query={graphql`
    query{
        allWordpressWpSpecjalizacje {
            edges {
              node {
                title
                slug
                id
                featured_media{
                    localFile {
                        publicURL
                    }
                }
              }
            }
          }
      }
    `}
    
    render={(data) => (
      <>
        <SpecializationCarousel data={data} {...props} />
      </>
    )}
  />
 )
